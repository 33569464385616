@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

/* Общий контейнер страницы подачи заявки */
.submit-review-page {
  max-width: 800px;
  margin: 120px auto 2rem; /* верхний отступ для избежания перекрытия фиксированным хедером */
  padding: 2rem;
  background-color: #fff;
  font-family: 'Inter', sans-serif;
  color: #333;
}

/* Заголовок страницы */
.page-title {
  font-size: 2.2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1.2rem;
}

/* Подзаголовок страницы */
.page-subtitle {
  font-size: 1.3rem;
  text-align: center;
  margin-bottom: 2rem;
  color: #555;
}

/* Секция с содержимым (форма) */
.content-section {
  margin-bottom: 2rem;
}

/* Стили для формы подачи заявки – без выделённых карточек */
.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #444;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background-color: #fafafa;
  box-sizing: border-box;
}

textarea {
  resize: vertical;
  min-height: 120px;
}

/* Кнопка отправки заявки */
.btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 0.9rem 2rem;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  display: block;
  margin: 2rem auto 0;
  transition: background-color 0.3s, transform 0.3s;
}

.btn:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

/* Сообщения об успехе и ошибках */
.success-message {
  color: #28a745;
  text-align: center;
  margin-bottom: 1rem;
}

.error-message {
  color: #dc3545;
  text-align: center;
  margin-bottom: 1rem;
}

/* Прогресс загрузки */
.upload-progress {
  text-align: center;
  font-size: 1rem;
  margin-bottom: 1.2rem;
}

.progress-bar {
  height: 6px;
  background-color: #007bff;
  margin-top: 0.5rem;
  border-radius: 3px;
}

/* Стили для анимации появления элементов */
.animate-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

/* Стили для списка отправленных заявок (если отображается на этой странице) */
.reviews-list {
  margin-top: 2.5rem;
}

.reviews-list h2 {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #333;
  text-align: center;
}

.reviews-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reviews-list li {
  padding: 1rem 0;
  border-bottom: 1px solid #e0e0e0;
  font-size: 1rem;
  color: #555;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.reviews-list li a {
  color: #007bff;
  text-decoration: none;
  font-weight: 500;
}

.reviews-list li a:hover {
  text-decoration: underline;
}

/* Глобальный отступ сверху для тела страницы, чтобы фиксированный хедер не перекрывал контент */
body {
  padding-top: 80px; /* Подберите значение, соответствующее высоте вашего хедера */
}

/* Адаптивность для мобильных устройств */
@media (max-width: 600px) {
  .submit-review-page {
    margin: 140px 1rem 2rem;
    padding: 1rem;
  }
  
  .page-title {
    font-size: 1.8rem;
  }
  
  .page-subtitle {
    font-size: 1.1rem;
  }
  
  .form-group input,
  .form-group textarea {
    padding: 0.6rem;
    font-size: 0.9rem;
  }
  
  .btn {
    padding: 0.8rem 1.5rem;
    font-size: 0.9rem;
  }
  
  .reviews-list h2 {
    font-size: 1.6rem;
  }
}
