
.tools-page {
  font-family: Arial, sans-serif;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.tools-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 40px;
}

.tool-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.tool-card:hover {
  transform: translateY(-5px);
}

.tool-card h2 {
  color: #007bff;
  margin-bottom: 10px;
}

.tool-card p {
  color: #666;
}

  /* Общие стили для всех страниц */
  body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f5f5f5;
  }
  
  .btn {
    display: inline-block;
    background-color: #4A90E2;
    color: #fff;
    padding: 0.8rem 1.5rem;
    border-radius: 4px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .btn:hover {
    background-color: #3a78c2;
  }
  
  @media (max-width: 768px) {
    .tools-grid,
    .journal-articles {
      grid-template-columns: 1fr;
    }
    
    .search-button {
      width: 100%;
      margin-top: 1rem;
    }
  }