.vertical-posters-container {
    position: relative;
    padding: 0 0 0 40px;
    margin-top: 40px;
  }
  
  .section-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  
  .vertical-posters-slider {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 2rem 0;
  }
  
  .vertical-posters-slider::-webkit-scrollbar {
    display: none;
  }
  
  .poster {
    flex: 0 0 240px;
    height: 360px;
    margin-right: 1rem;
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: #ffffff;
    text-decoration: none;
    border-radius: 10px;
    transition: transform 0.3s;
    overflow: hidden;
    position: relative;
    background-size: cover;
    background-position: center;
  }
  
  .poster:hover {
    transform: scale(1.05);
  }
  
  .poster-content {
    padding: 1rem;
    background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);
    width: 100%;
  }
  
  .poster h4 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .poster p {
    font-size: 0.9rem;
    opacity: 0.8;
  }
  
  .slider-controls {
    position: absolute;
    top: 0;
    right: 20px;
    display: flex;
    gap: 10px;
  }
  
  .slider-arrow {
    background: rgba(0, 0, 0, 0.1);
    border: none;
    width: 30px;
    height: 30px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
    color: #000000;
  }
  
  .slider-arrow:hover {
    background: rgba(0, 0, 0, 0.2);
  }