.tutor-plus-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
    font-family: 'Inter', sans-serif;
  }
  
  .title {
    font-size: 2.5rem;
    font-weight: 600;
    color: #333;
  }
  
  .subtitle {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 1.5rem;
  }
  
  .info-section {
    background: #f9f9f9;
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
  }
  
  .features-list {
    text-align: left;
    margin: 1rem auto;
    padding-left: 1.5rem;
  }
  
  .features-list li {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: #444;
  }
  
  .cta-section {
    margin-top: 1.5rem;
  }
  
  .telegram-button {
    display: inline-flex;
    align-items: center;
    background-color: #0088cc;
    color: white;
    padding: 0.8rem 1.5rem;
    font-size: 1.2rem;
    border-radius: 8px;
    text-decoration: none;
    font-weight: 600;
    transition: background 0.3s;
  }
  
  .telegram-button:hover {
    background-color: #0077b6;
  }
  
  .telegram-icon {
    margin-right: 8px;
    font-size: 1.4rem;
  }
  