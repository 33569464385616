@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.survey-take {
  font-family: 'Inter', sans-serif;
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.survey-take h1 {
  font-size: 2.5rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
  letter-spacing: -0.03em;
  color: #000000;
  text-align: center;
}

.survey-take form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.survey-take input[type="text"],
.survey-take input[type="date"] {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  transition: border-color 0.3s;
}

.survey-take input[type="text"]:focus,
.survey-take input[type="date"]:focus {
  outline: none;
  border-color: #000000;
}

.survey-take .question {
  margin-bottom: 1.5rem;
}

.survey-take .question p {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.survey-take button[type="submit"] {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: #ffffff;
  background-color: #000000;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  align-self: flex-start;
}

.survey-take button[type="submit"]:hover {
  background-color: #333333;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .survey-take {
    padding: 1.5rem;
  }

  .survey-take h1 {
    font-size: 2rem;
  }
}