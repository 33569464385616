@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.journal-page {
  width: 100%;
  background-color: #fff;
  padding: 4rem 2rem;
}

.page-title {
  font-size: 3.5rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 2rem;
  letter-spacing: -0.03em;
  color: #000000;
}

.page-subtitle {
  font-size: 1.2rem;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 3rem;
  font-weight: 400;
  color: #0a0a0a;
}

.content-section {
  max-width: 1200px;
  margin: 0 auto;
}

.grid-layout {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;
}

.card {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

.card h3 {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #000000;
}

.card p {
  font-size: 1rem;
  color: #333333;
}

.btn {
  display: inline-block;
  padding: 1rem 2rem;
  font-size: 1rem;
  text-decoration: none;
  border-radius: 8px;
  background-color: #000;
  color: #fff;
  transition: background-color 0.3s, transform 0.3s;
}

.btn:hover {
  background-color: #333;
  transform: translateY(-3px);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.form-group textarea {
  min-height: 150px;
}

@media (max-width: 1200px) {
  .page-title { font-size: 3rem; }
  .card h3 { font-size: 1.6rem; }
}

@media (max-width: 768px) {
  .page-title { font-size: 2.5rem; }
  .journal-page { padding: 3rem 1rem; }
  .grid-layout {
    grid-template-columns: 1fr;
  }
}