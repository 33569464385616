@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.journal-page {
  width: 100%;
  background-color: #fff;
}

.full-screen {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero {
  text-align: center;
  padding: 0 2rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.hero-content {
  max-width: 1000px;
  margin: 0 auto;
}

h1 {
  font-size: 4.5rem;
  font-weight: 400;
  margin-bottom: 1rem;
  letter-spacing: -0.04em;
  line-height: 1.1;
  color: #000000;
}

.animate-word {
  display: inline-block;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.hero-subtitle {
  font-size: 1.5rem;
  max-width: 800px;
  margin: 0 auto 2rem;
  font-weight: 400;
  color: #0a0a0a;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.9s ease, transform 0.9s ease;
}

.key-features {
  padding: 6rem 2rem;
}

h2 {
  font-size: 3.5rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 1.5rem;
  letter-spacing: -0.03em;
  color: #000000;
}

.section-subtitle {
  font-size: 1.2rem;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 3rem;
  font-weight: 400;
  color: #0a0a0a;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  max-width: 1200px;
  margin: 0 auto;
}

.animated-block {
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  padding: 2rem;
}

.animated-block h3 {
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #000000;
}

.animated-block p {
  font-size: 1rem;
  font-weight: 400;
  color: #0a0a0a;
}

.cta {
  padding: 4rem 2rem;
  text-align: center;
}

.cta h2 {
  font-size: 3rem;
  color: #000000;
  margin-bottom: 2rem;
}

.cta p {
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto 2rem;
  color: #0a0a0a;
}

.cta-button {
  display: inline-block;
  padding: 1rem 2rem;
  font-size: 1rem;
  text-decoration: none;
  border-radius: 30px;
  background-color: #000;
  color: #fff;
  transition: background-color 0.3s, transform 0.3s;
}

.cta-button:hover {
  background-color: #333;
  transform: translateY(-3px);
}

@media (max-width: 1200px) {
  h1 { font-size: 3.5rem; }
  h2 { font-size: 3rem; }
  .features-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  h1 { font-size: 2.5rem; }
  h2 { font-size: 2.5rem; }
  .features-grid {
    grid-template-columns: 1fr;
  }
  .hero-subtitle {
    font-size: 1.2rem;
  }
}