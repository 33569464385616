@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.admin-page {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Inter', sans-serif;
  color: #333;
  background-color: #fff;
}

.admin-page h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.message {
  text-align: center;
  font-size: 1.1rem;
  margin-bottom: 1rem;
  color: green;
}

.reviews-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.reviews-table th,
.reviews-table td {
  border: 1px solid #ddd;
  padding: 0.75rem;
  text-align: center;
}

.reviews-table th {
  background-color: #f5f5f5;
  font-size: 1rem;
  font-weight: 600;
}

.reviews-table td a {
  color: #007bff;
  text-decoration: none;
}

.reviews-table td a:hover {
  text-decoration: underline;
}

.reviews-table input[type="file"] {
  margin-bottom: 0.5rem;
}

.reviews-table button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.reviews-table button:hover {
  background-color: #333;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .reviews-table th,
  .reviews-table td {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
}
