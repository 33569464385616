/* frontend/src/pages/tools/SourceGenerator.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.source-generator {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  font-family: 'Inter', sans-serif;
  background-color: #fff;
}

.source-generator h1 {
  font-size: 3.5rem;
  font-weight: 400;
  color: #000000;
  margin-bottom: 2rem;
  text-align: center;
  letter-spacing: -0.03em;
}

.sources-list {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  min-height: 100px;
}

.empty-list {
  color: #999;
  text-align: center;
  font-style: italic;
}

.source-item {
  margin-bottom: 1rem;
  font-size: 1rem;
  color: #333333;
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.button-group button,
.source-form button {
  padding: 1rem 2rem;
  font-size: 1rem;
  text-decoration: none;
  border-radius: 8px;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button-group button:hover,
.source-form button:hover {
  background-color: #333;
  transform: translateY(-3px);
}

.generator-interface {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
}

.category-selection {
  flex: 1;
  max-width: 300px;
}

.category {
  margin-bottom: 1rem;
}

.category-button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 30px;
  background-color: #f0f0f0;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 0.5rem;
}

.subcategory-button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 30px;
  background-color: #ffc17e;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 0.5rem;
}

.category-button.active,
.subcategory-button.active {
  background-color: #000;
  color: #fff;
}


.category-button:hover,
.subcategory-button:hover {
  background-color: #e0e0e0;
}

.subcategory-button {
  padding-left: 2rem;
}

.source-form {
  flex: 2;
}

.source-form input {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
}

.source-form button {
  width: 100%;
}

@media (max-width: 768px) {
  .source-generator h1 {
    font-size: 2.5rem;
  }
  
  .generator-interface {
    flex-direction: column;
  }

  .category-selection {
    max-width: 100%;
  }
  
  .button-group {
    flex-direction: column;
  }
  
  .button-group button {
    width: 100%;
  }
}