.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
}

.header.hidden {
  transform: translateY(-100%);
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
}

.logo-text {
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 0.5rem;
}

.desktop-menu {
  display: flex;
  list-style-type: none;
}

.desktop-menu li {
  margin-left: 1.5rem;
  position: relative;
}

.desktop-menu a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
}

.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 4px;
  padding: 0.5rem 0;
  display: none;
}

.submenu a {
  display: block;
  padding: 0.5rem 1rem;
  white-space: nowrap;
}

li:hover .submenu {
  display: block;
}

.mobile-menu-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.mobile-menu-toggle span {
  width: 2rem;
  height: 0.25rem;
  background-color: #333;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

.auth-icon {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}



.mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1001;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

.mobile-menu-overlay.open {
  transform: translateX(0);
}

.mobile-menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  border-bottom: 1px solid #eee;
}

.close-menu {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
.user-actions {
  display: flex;
  align-items: center;
}

.profile-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f0f0f0;
  color: #333;
  text-decoration: none;
}

.user-avatar {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
}

.login-icon {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #333;
}

.mobile-menu {
  padding: 2rem;
}

.mobile-menu a {
  display: block;
  padding: 1rem 0;
  text-decoration: none;
  color: #333;
  font-size: 1.2rem;
  border-bottom: 1px solid #eee;
}

.mobile-submenu {
  padding-left: 1rem;
}

.mobile-submenu a {
  font-size: 1rem;
}

.blur-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  backdrop-filter: blur(5px);
  z-index: 999;
}

@media (max-width: 768px) {
  .desktop-menu {
    display: none;
  }

  .mobile-menu-toggle {
    display: flex;
  }
}