/* src/pages/journal/SubmitArticlePage.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.submit-article-page {
  font-family: 'Inter', sans-serif;
  padding: 4rem 2rem;
  background-color: #f8f9fa;
}

.page-title {
  font-size: 3.5rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 1rem;
  letter-spacing: -0.03em;
  color: #000000;
}

.page-subtitle {
  font-size: 1.2rem;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 3rem;
  font-weight: 400;
  color: #0a0a0a;
}

.content-section {
  max-width: 1200px;
  margin: 0 auto;
}

.grid-layout {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 3rem;
}

.card {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.card h2 {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #000000;
}

.card ul {
  padding-left: 1.5rem;
}

.card li {
  margin-bottom: 0.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.form-group textarea {
  min-height: 150px;
}

.btn {
  display: inline-block;
  padding: 1rem 2rem;
  font-size: 1rem;
  text-decoration: none;
  border-radius: 8px;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.btn:hover {
  background-color: #333;
  transform: translateY(-3px);
}
.success-message {
  color: #28a745;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
}
.upload-progress {
  margin-bottom: 15px;
  background-color: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;
}

.progress-bar {
  height: 20px;
  background-color: #4CAF50;
  width: 0;
  transition: width 0.3s ease;
}
.error-message {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
}
.animate-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

@media (max-width: 1200px) {
  .page-title { font-size: 3rem; }
  .grid-layout { grid-template-columns: 1fr; }
}

@media (max-width: 768px) {
  .page-title { font-size: 2.5rem; }
  .submit-article-page { padding: 3rem 1rem; }
}