.auth-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #F5E6D3;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .auth-container h2 {
    text-align: center;
    color: #333333;
    margin-bottom: 20px;
  }
  
  .auth-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #333333;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .auth-button {
    background-color: #4A4A4A;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .auth-button:hover {
    background-color: #333333;
  }
  
  .auth-link {
    text-align: center;
    margin-top: 15px;
  }
  
  .auth-link a {
    color: #4A4A4A;
    text-decoration: none;
  }
  
  .auth-link a:hover {
    text-decoration: underline;
  }