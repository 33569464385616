@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #000000;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
}

h1, h2 {
  font-size: 3rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 2rem;
  letter-spacing: -0.03em;
  color: #000000;
}

form {
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  margin-bottom: 3rem;
}

input[type="text"] {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
}

button {
  display: inline-block;
  padding: 1rem 2rem;
  font-size: 1rem;
  text-decoration: none;
  border-radius: 8px;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

button:hover {
  background-color: #333;
  transform: translateY(-3px);
}

button[type="button"] {
  background-color: #f7f7f7;
  color: #000;
  margin-right: 1rem;
}

button[type="button"]:hover {
  background-color: #e0e0e0;
}

.survey-link {
  margin-top: 2rem;
  padding: 1.5rem;
  background-color: #f7f7f7;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.survey-link a {
  color: #000;
  text-decoration: none;
  font-weight: 500;
}

.surveys-list {
  margin-top: 3rem;
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.surveys-list select {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
  margin-top: 1rem;
}

.results-container {
  margin-top: 3rem;
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.questions-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.question-button {
  padding: 1rem;
  background-color: #f0f0f0;
  border: none;
  border-radius: 8px;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  font-family: 'Inter', sans-serif;
}

.question-button:hover {
  background-color: #e0e0e0;
  transform: translateY(-3px);
}

.question-button.active {
  background-color: #000;
  color: #fff;
}

.results-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 2rem;
}

.results-table th,
.results-table td {
  border: 1px solid #ddd;
  padding: 1rem;
  text-align: left;
}

.results-table th {
  background-color: #f2f2f2;
  font-weight: 600;
}

.results-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.sortable {
  cursor: pointer;
}

.sortable:hover {
  background-color: #e8e8e8;
}

@media (max-width: 768px) {
  .container {
    padding: 2rem 1rem;
  }
  
  h1, h2 {
    font-size: 2.5rem;
  }
  
  .questions-list {
    grid-template-columns: 1fr;
  }
}