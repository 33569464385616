.banner-slider-container {
    width: calc(100% - 40px);
    margin-left: 20px;
    overflow: hidden;
    position: relative;
  }
  
  .banner-slider {
    display: flex;
    transition: transform 0.5s ease;
  }
  
  .banner {
    flex: 0 0 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 20px;
    overflow: hidden;
  }
  
  .banner-content {
    text-align: center;
    max-width: 80%;
  }
  
  .banner h2 {
    font-size: 4rem;
    margin-bottom: 1rem;
  }
  
  .banner p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .banner-button {
    display: inline-block;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    text-decoration: none;
    color: #000000;
    background-color: #ffffff;
    border-radius: 30px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .banner-button:hover {
    background-color: #000000;
    color: #ffffff;
  }
  
  .slider-controls {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    gap: 10px;
  }
  
  .slider-arrow {
    background: rgba(255, 255, 255, 0.5);
    border: none;
    width: 40px;
    height: 40px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
    color: #000000;
  }
  
  .slider-arrow:hover {
    background: rgba(255, 255, 255, 0.8);
  }
  
  .slider-dots {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .dot.active {
    background-color: #ffffff;
  }