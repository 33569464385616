/* Общие стили для профиля */
.profile-container {
  max-width: 800px;
  margin: 100px auto 2rem;  /* добавлен верхний отступ, чтобы хедер не перекрывал профиль */
  padding: 2rem;
  font-family: 'Inter', sans-serif;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
}

.profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.profile-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #fff;
  font-weight: bold;
  margin-right: 1rem;
  flex-shrink: 0;
}

.profile-header h1 {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.profile-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.profile-section {
  background-color: #f9f9f9;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.1);
}

.profile-section h2 {
  font-size: 1.5rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 1rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
}

.profile-section p {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: #666;
}

/* Стилизация форм и полей редактирования */
input, select, textarea {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
}

textarea {
  min-height: 100px;
  resize: vertical;
}

/* Стилизация блока выбора цвета аватара */
.avatar-color-selection {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.avatar-color-option {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s;
}

.avatar-color-option.selected {
  border-color: #000;
}

/* Стили для ссылок на скачивание файлов */
.profile-section a {
  color: #007bff;
  text-decoration: none;
  font-weight: 500;
}

.profile-section a:hover {
  text-decoration: underline;
}

/* Стили для списка заявок на проверку */
.profile-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.profile-section li {
  margin-bottom: 1rem;
  font-size: 1rem;
  color: #444;
  border-bottom: 1px solid #eee;
  padding-bottom: 0.5rem;
}

/* Футер профиля */
.profile-footer {
  margin-top: 2rem;
  font-size: 0.9rem;
  color: #888;
  border-top: 1px solid #ddd;
  padding-top: 1rem;
}

/* Блок действий (кнопки) */
.profile-actions {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}

button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s, transform 0.3s;
  margin-left: 1rem;
}

button:hover {
  background-color: #333;
  transform: translateY(-2px);
}

/* Сообщения об ошибках и загрузке */
.loading, .error {
  text-align: center;
  font-size: 1.2rem;
  color: #666;
  margin-top: 2rem;
}

.error {
  color: #ff4444;
}

/* Адаптивность для мобильных устройств */
@media (max-width: 600px) {
  .profile-container {
    margin-top: 120px;  /* дополнительный отступ, если хедер фиксированный */
    padding: 1rem;
  }
  
  .profile-content {
    grid-template-columns: 1fr;
  }
  
  .profile-header {
    flex-direction: column;
    text-align: center;
  }
  
  .profile-avatar {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
