.survey-results-container {
  font-family: 'Inter', sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

h1 {
  font-size: 3rem;
  font-weight: 400;
  margin-bottom: 2rem;
  color: #000000;
  text-align: center;
}

h2 {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #000000;
}

select {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 2rem;
  background-color: #fff;
}

.survey-results {
  display: flex;
  gap: 2rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.questions-list {
  flex: 1;
  max-width: 300px;
}

.question-button {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
  text-align: left;
  background-color: #f0f0f0;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
}

.question-button:hover {
  background-color: #e0e0e0;
  transform: translateY(-2px);
}

.question-button.active {
  background-color: #000;
  color: #fff;
}

.answers-table {
  flex: 2;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 0.75rem;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

th {
  font-weight: 600;
  background-color: #f7f7f7;
}

.sortable {
  cursor: pointer;
}

.sortable:hover {
  background-color: #e8e8e8;
}

@media (max-width: 768px) {
  .survey-results {
    flex-direction: column;
  }

  .questions-list {
    max-width: 100%;
  }
}